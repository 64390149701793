import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../Style/EssayPage.css"
import "../Style/WrongPage.css"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="WrongPage">
    <div className="CautionBlock">
          <h2 className="CautionTitle">Oops... ¯\_(ツ)_/¯</h2>
          <div className="CautionContent">
          <div className="CautionStyle">Sorry, but if you see this message, most likely the page you're looking for was wiped out or never existed.</div>
          <div className="CautionStyle">If you're sure it must exist, but it doesn't, please contact me via <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">Twitter DMs</a></div>
         
          </div>
        </div>
        </div>
  </Layout>
)

export default NotFoundPage
